export default {
  oea: "OEA",
  exploreOEA: "Explore OEA",
  news: "News",
  logout: "Logout",
  homeTitle: "Order of Engineers & Architects <br/> in Tripoli, Lebanon",
  footerCopyrights:
    "Order of Engineers & Architects in Tripoli - Copyright 2022",
  aboutOEA: "About OEA",
  boardOfMembers: "Board Members",
  organizationChart: "Organization Chart",
  branches: "Branches",
  branchesSubtitle:
    "According to Article 6-4 of the internal regulations of the syndicate, each branch shall have an office body consisting of a president, who is the branch's representative in the syndicate council, and four members elected by the branch's general assembly in accordance with Article 6-3 of these regulations. Elections for branch offices whose terms have ended shall be conducted by the general assembly of each branch during the month of May every year for a period of three years.",
  associations: "Committees",
  trainings: "Trainings",
  events: "Events",
  gallery: "Gallery",
  lawsAndRegulations: "Laws & regulations",
  engZone: "Engineers & Architects Zone",
  contactUs: "Contact Us",
  contactOea: "Contact OEA",
  contactLaboratory: "Contact Laboratory",
  contactTrainingCenter: "Contact Training Center",
  trainingCenter: "Training Center",
  "training-Center": "Training Center",
  visitUs: "Visit Us",
  visitOea: "Visit OEA",
  visitLaboratory: "Visit Laboratory",
  visiTrainingCenter: "Visit Training Center",
  latestFeeds: "Latest News",
  aboutUs: "About Us",
  upcomingEvents: "Upcoming Events",
  privacyPolicy: "Privacy Policy",
  termOfUse: "Terms Of Use",
  services: "Services",
  shares: "Share",
  likes: "Likes",
  views: "Views",
  All: "All",
  all: "All",
  "Training-Center": "Training Center",
  "OEA-Support": " OEA Support",
  readMore: "Read More",
  dateStart: "Date Start",
  startingFrom: "Starting From:",
  dateEnd: "Date End",
  instructors: "Instructors",
  alreadyRegistered: "You Are Already Registered",
  registerClosed: "The Registration is Closed",
  viewRegistrationDetails: "View Registration Details",
  register: "Register",
  allDay: "All Day",
  start: "Start",
  end: "End",
  Activities: "Activities",
  announcements: "Circulars - Council Decisions",
  Announcements: "Announcements",
  latestAnnouncements: "Latest Announcements",
  Mortality: "Mortality",
  discountsAndBenefits: "Discounts & Benefits",
  addCompany: "Add Company",
  companyName: "Company Name",
  companyLocation: "Location",
  uploadCompanyLocation: "Company Location",
  uploadCompanyLogo: "Company Logo",
  "Companies Directory": "Companies Directory",
  companyPhone: "Company Phone",
  companyEmail: "Company Email",
  companyWebsite: "Company Website",
  companyDescription: "Company Description",
  "Add Company": "Add Company",
  insurance: "Insurance",
  registrationTo: "Registration to",
  technicalDocuments: "Technical Documents",
  MyRegistrationFor: "My Registration For The",
  myRegistration: "My Registration",
  title: "Title",
  date: "Date",
  name: "Name",
  email: "Email",
  phoneNumber: "Phone Number",
  whatsAppNumber: "WhatsApp",
  additionalInfo: "Additional Info",
  viewTrainingDetails: "Training Details",
  editRegistration: "Edit Registration",
  cancelRegistration: "Cancel Registration",
  engDirectory: "Engineers Directory",
  major: "Major",
  skills: "Skills",
  myComplaints: "My Suggestions",
  newComplaint: "New Suggestion",
  complaint: "Suggestion",
  complaintImage: "Suggestion Image",
  complaintVoice: "Suggestion Voice",
  complaintDescription: "Suggestion Description",
  complaintStatus: "Suggestion Status",
  "MY SUGGESTIONS": "MY SUGGESTIONS",
  "ADD SUGGESTION": "ADD SUGGESTION",
  recordYourComplaint: "Record Your Suggestion",
  uploadSuggestionImage: "Upload Suggestion image",
  uploadingVoice: "Uploading voice",
  complaintAdded: "Suggestion submitted",
  editComplaint: "Edit suggestion",
  edit: "Edit",
  voiceFailedToLoad: "Failed to load, retry",
  complaintDeleted: "Suggestion deleted",
  noComplaints: "You have no suggestions",
  initiated: "Initiated",
  share: "Share",
  underInvestigation: "Under investigation",
  closed: "Closed",
  sureToDeleteComplaint: "Are you sure you want to delete this suggestion?",
  errorUploadingVoice: "Error uploading voice",
  ciscoTrainings: "Cisco Trainings",
  calculator: "Calculator",
  "Registration Fees": "Registration Fees",
  "Engineer Fees": "Engineer Fees",
  back: "Back",
  calculatorResultNote:
    "This result is for guidance only. You need to contact the order to get the accurate fees",
  usdRate: "1 USD = 1500 LBP",
  engFees: "Engineer Fees",
  penaltyRate: "Penalty rate",
  penaltyAmount: "Penalty amount",
  numberOfLateYears: "Number of years since graduation",
  year: "Year(s)",
  registrationFees: "Affiliation fees",
  priceOfTerritory: "Cost of the project",
  annualFees: "Annual fees",
  nbyears: "Number of years",
  pickCalculationMethod: "Calculation Method",
  byArea: "By Area",
  byCost: "By Cost",
  projectTotal: "Project Surface",
  sqm: "Sqm",
  inputValue: "Input value",
  projectCategory: "Project category",
  projectSubCategory: "Project sub-category",
  priceValueRange: "Price value range",
  complexLevel: "Complex level",
  calculate: "Calculate",
  constructionTable: "Construction Table",
  pricePer1sqm: "Price per 1 sqm (as per table)",
  percentageOfEngineerFees: "Percentage of engineer fees",
  percentageOfEngFees: "Percentage of engineer fees (as per table)",
  primaryStudyFees: "Primary study fees APS",
  detailedStudyFees: "Detailed study fees APS",
  licenceFileFees: "License file fees",
  orderFees: "Order fees",
  orderFeesOutside: "Order fees - outside Tripoli",
  designFees: "Design and executive details fees",
  executiveDocumentFees: "Executive document fees",
  supervisingFees: "Supervising fees",
  constructionLicenseFees: "Construction license fees",
  executivePlansFees: "Executive Plans fees",
  result: "Result",
  select: "Select",
  simple: "Simple",
  normal: "Normal",
  luxurious: "Luxirious",
  complicated: "Complex",
  returnsToPartEng:
    "Returned back to the co-engineer 40% of the returns of OEA",
  electEngFees: "Payed to the electrical engineer from the returns of OEA",
  mechEngFees: "Payed to the mechanical engineer from the returns of OEA",
  quotaPercentagePartFees:
    "The percentage of the total fee of the participant (according to the quota distribution ratio)",
  elecPerc: "Percentage of the total fee of the electricity engineer",
  mechanicalPerc: "Percentage of the total fee of the Mechanical Engineer",
  remainingForMainEng:
    "Net Remaining For The Responsible Engineer Of OEA Returns",
  fundReceipts: "Total Value Of Receipts Payable To The Fund",
  pensionFund: "Pension Fund",
  reports: "Reports",
  payOnline: "Pay Online",
  polls: "Polls",
  jobs: "Jobs",
  position: "Position",
  deadline: "Deadline",
  qualifications: "Qualifications",
  jobDetails: "Details",
  contactInfo: "Contact info",
  profile: "My Profile",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedIn: "Linkedin",
  gender: "Gender",
  male: "Male",
  skills: "Skills",
  searchSkill: "Search Skills",
  complaints: "Suggestions",
  engProfile: "Profile",
  cvUrl: "CV",
  female: "Female",
  uploadingPdf: "Uploading Pdf",
  errorUploadingPdf: "Error Uploading Pdf",
  isPublicProfile: "Visible In Engineers Directory",
  major: "Profession",
  twitter: "Twitter",
  userName: "Username",
  EngId: "Engineer ID",
  myRegistrations: "My Registrations",
  myComplaints: "My Suggestions",
  facebookAccount: "Facebook",
  twitterAccount: "Twitter",
  emailAddress: "Email",
  linkedinAccount: "Linkedin",
  instagramAccount: "Instagram",
  save: "Save",
  delete: "Delete",
  submit: "Submit",
  next: "Next",
  engineerId: "Engineer ID",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  countryCode: "Country Code",
  username: "Username",
  resetPassword: "Reset Password",
  password: "Password",
  rememberMe: "Remember Me",
  joinOEA: " New Account",
  forgotPassword: "Forgot Password",
  enroll: "Enroll",
  changePhoneNumber: "Change Your Phone Number",
  unableToConnectToServer: "An Error Occured, Please Try Again Later",
  engineerId: "Engineer ID",
  firstName: "First Name",
  email: "Email",
  email2: "Email",
  phoneNumber: "Phone Number",
  allFieldMandatory: "All fields are mandatory",
  enrollThankYou:
    "Thank you for being interested in enrolling with OEA Tripoli",
  enrollDetailsSubmitted: "Your details has been successfully submitted.",
  enrollInfo:
    "We're now reviewing your information to check eligibility. You'll get an SMS to your phone number with a code within the next 24 hours.",
  enrollCode: "Input code you received by SMS",
  didntReceiveEnrollCode: "Didn't received your code?",
  resendCode: "Resend Code",
  correctPhone: "Correct Phone Number",
  welcomeInfo: "Welcome To OEA Tripoli Mobile App",
  createPasswordInfo: "Create Your Own Password",
  confirmPassword: "Confirm Password",
  goToSignIn: "Go To Sign In",
  iHaveACode: "You Have A Code To Activate The Account.",
  activateAccount: "Activate Account",
  enterEngId: "Please Enter Your Engineer Id",
  resetCodeReceived: "Already Received A Reset Code?",
  rememberedPassword: "Back To",
  pleaseChooseResetPasswordMethod: "Send Code by ",
  sms: "SMS",
  requestToJoin: "Request To Join",
  tryAgainError: "An Error Occured, Please Try Again Later",
  useCodeToLogin: "Please Use The Verification Code To Login",
  enterNewPass: "Enter New Password",
  confirmNewPass: "Confirm New Password",
  alreadySentRequest: "Already Sent A Request?",
  verifyEnrollement: "Verify Enrollment",
  alreadyHaveAccount: "Already Have An Account?",
  youMustBeLoggedIn: "You Must Sign In To Use This Feature",
  youMustBeLoggedInToSee: "Please Sign In To Take Advantage Of This Feature",
  confirmEnroll: "Confirm Enroll",
  signinSentence: "Sign In To Benefit From Engineers' Services Online",
  smsCode: "SMS Verification Code",
  retypePass: "Re-Type Password",
  facingProblem: "Facing a Problem?",
  sendNewRequest: "Send a New Enrollment Request",
  newUser: "New User?",
  joinToOea: "Join OEA",
  forgotPass: "Forgot Password?",
  fullName: "Full Name",
  addPerson: "Add Person",
  removePerson: "Remove Person",
  comingSoon: "Coming Soon",
  pleaseWait: "Please Wait ...",
  "register-to-event": "Register To Event",
  "register-to-training": "Register To Training",
  Event: "Event",
  Training: "Training",
  ok: "Ok",
  home: "Home",
  enterPassword: "Enter New Password",
  eorder: "E-Order",
  introSentence:
    "To Carry Out Your Administrative And Financial Transactions, Please Click On The Following Link:",
  clickHere: "Click Here",
  renewJobDeclaration: "Renew Job Declaration",
  continueFeesPayment: "Continue Fees Payment",
  statementYear: "Professional Statement For The Year",
  generalInfo: "General Infos",
  engineerName: "Engineer Name",
  chapter: "Chapter",
  subChapter: "SubChapter",
  workType: "Work Type",
  workLocation: "Work Location",
  homeAddress: "Home Address",
  country: "Country",
  province: "Province",
  city: "City",
  district: "District",
  street: "Street",
  building: "Building",
  floor: "Floor",
  appartment: "Appartment",
  mobile: "Mobile",
  isForMailing: "(Is For Mailing)",
  workAddress: "Work Address",
  oops: "Oops! A problem occured while loading the page",
  tryAgain: "Click Here To Try Again",
  law: `Pursuant to the Article 8-9 of the rules of procedure:
            The engineer shall personally write down all the information contained in the professional statement belonging to him on his responsibility.
            And that any false information contained in the aforementioned statement exposes its owner to the suspension of transactions in the Syndicate
            in accordance with a decision issued by the Council of the Syndicate in this regard.
            It also subject him to disciplinary sanctions provided for by law.`,
  IAccept: "I Accept",
  clickToContinue: "Click Here To Continue",
  quotaLaw:
    "Only Consulting Engineers (Who Obtain A Quota) Are Authorized To Renew The Professional Statement At The Beginning Of The Year, ie On firstDate. As For The Other Engineers, Their Professional Data Is Renewed At The Beginning Of The Order Year, i.e. On secondDate.",
  visitOEA: `You Can't Change Your Position Online.<br/> To Change Anything In The Status Of "Consulting Engineers", <br/>You Must Come Personally To The Order Of Engineers. <br/><br/> You Can Contact The Order For More Information<br/> Via Phone Number : 06413110 <br/>Or<br/> Via E-mail : info@oea-Tripoli.org.lb`,
  statusPreviousYear:
    "The Status Of Your Professional Statement For The Previous Year Is:",
  changePosition: "Change Position",
  continueSamePosition: "Continue In The Same Position",
  delayVisitOea: `The Date Of Your Last Professional Statement Dates Back More Than A Year, So You Must Personally Come To The Order <br/><br/>Or Contact The Following Number: 06413110 <br/>Or Via E-mail: info@oea-Tripoli.org.lb`,
  everythingUpToDate:
    "Your Job Declaration is Up-to-date. Thank you. You Can Only View Your Last Job Declaration.",
  updatesText: `If There's Any Change, Please Edit Your Home And Work Address`,
  uploadDocuments: "Please Upload The Following Documents",
  noDocuments: "No Documents Required",
  remarks: "Remarks",
  responsibility:
    "The Information Received Is At Your Responsibility, And any False Information May Expose You To The Suspension Of Your Transactions And To Disciplinary Sanctions.",
  finish: "Finish Transation",
  workForEngineer: "Work For Engineer",
  workForEngineerID: "Work For Engineer ID",
  partnerEngineer: "Partner Engineer",
  partnerEngineerId: "Engineer's Partner ID",
  quotaForEngineer: "Quota For Engineer",
  quotaForEngineerID: "Quota Engineer ID",
  govInstitute: "Gov Institute",
  govInstituteID: "Gov Institute ID",
  govInstituteDesc: "Gov Institute Description",
  downloadFile: "To Download The Partnership Contract, Please Click Here.",
  downloadFileReceipt: "To Download The Receipt, Please Click Here",
  pictureNote:
    "Please note that you can only upload a picture or a pdf file with a maximum size of 1 MB.",
  bigFile: "Please upload a file less than 1MB",
  requestEnrollment:
    "Your Enrollment Request Is Under Review<br/><br/> We Will Verify Your Information then Send You A Verification Code On Your Mobile Number And Email To Be Able To Sign In",
  userDoesNotExists: "User ID not found",
  wrongPassword: "Incorrect Password",
  wrongData: "Incorrect Data",
  alreadyExists: "User Already Exists",
  doseNotExists: "User Does Not Exist",
  emailDoesNotExists: "Invalid Email",
  pendingReq: "Your Request Is Pending Approval",
  expiredCode: "The Verification Code Has Expired",
  requestDoesNotExist: "Request does not exist",
  codeUsed: "This Verification Code is Already Used",
  wrongCode: "Wrong Verification Code",
  confirmEnrollment: "Confirm Enrollment",
  yourCV: "Your CV",
  send: "Send",
  updateTypeLocationWork: "Please Modify Your Work Type And Location",
  requestExists: "You Have Already Sent A Request",
  engZoneSentence: ` The Engineers' Zone Offers Its Members a Customized Bouquet of Services and Features to Enhance Their Experience and Support Their Needs`,
  loginSentence: `Log In and Benefit From The OEA Engineers' Zone`,
  "Building License Transactions": "Building License Transactions",
  "Restoration / Connection Permit / Demolition / Designation":
    "Restoration / Connection Permit / Demolition / Designation",
  searchIn: "Search in",
  registrationclosed: "Registration Closed",
  visitTrainingCenter: "Visit Training Center",
  noDataAvailable: "No Data Available",
  noTrainingsAvailable: "No Trainings Available",
  wrongConfirmPassword: "Please Make Sure Your Passwords Match",
  wrongOldPassword: "Your Old Password Appears To Be Incorrect",
  changePassword: "Change Password",
  passwordChangedSuccessfully:
    "Your Password Has Been Changed Successfully. Use Your New Password To Login",
  personInfo: "Person Info",
  status: "Status",
  financialStatement: "Financial Statement",
  mandatoryUnpaidDues: "Mandatory unpaid dues",
  optionalUnpaidDues: "Unpaid dues",
  unpaidReceipt: "Unpaid Receipt",
  date: "Date",
  dueDate: "Due date",
  amount: "Amount",
  numberReceipt: "Receipt number",
  description: "Description",
  payReceiptB: "Please pay your unpaid receipts before issuing a new receipt",
  createdJobDeclaration: "Your administrative transactions have been completed",
  generatedReceipt:
    "Your financial transaction has been successfully completed",
  printReceipt: "Back to print receipt",
  noFinancialStatment: "You do not have any financial transactions",
  print: "Print",
  cantPrint:
    "This transaction is old, if it was paid, please send a copy to the following e-mail",
  skip: "Skip",
  batch: "batch",
  paymentTerms: "Payment terms",
  years: "The years",
  withoutInterest: "without interest",
  and: "and",
  feeAmount: "Fee Amount",
  interestAmount: "Interest Amount",
  pendingOperation:
    "You have a previous annual subscription transaction pending, you must contact the financial department",
  annualSubscription: "Annual subscription",
  fileCode: "File code",
  engineerNumber: "Engineer number",
  fileType: "File type",
  description: "Description",
  notes: "Notes",
  dateOfReceipt: "Date of receipt",
  deliveryDate: "Delivery date",
  currentStatusFile: "The current status of the file",
  lastUserHasFile: "The name of the last user or employee who has the file",
  licenseFileSentence:
    "To perform your technical transactions, Please Click On The Following Link:",
  licenseFile: "License Operation",
  enterFileCode: "Enter your operation code",
  extension: "Extension",
  employeeName: "Employee Name",

  //SEO Desription
  aboutOeaDescription: "About the order of engineers and architects in Tripoli",
  boardmembersDescription:
    "Board of members of the order of engineers and architects in Tripoli",
  branchesDescription:
    "Branches in the order of engineers and architects in Tripoli",
  committeesDescription:
    "The committees of the order of engineers and architects in Tripoli",
  organizationDescription:
    "The organiztaion chart of the order of engineers and architects in Tripoli",
  benefitsDescription:
    "Discounts and benefits offered by the Order of Engineers and Architects in Tripoli",
  companiesDescription:
    "Companies directory where you can find many companies with their details ",
  contactusDescription:
    "Contact the order of engineers and architects in Tripoli",
  insuranceDescription:
    "Insurance service offered by the order of engineers and architects in Tripoli",
  engineersDirectoryDescription:
    "Engineers directory where you can find a large number of engineers profiles with their contact info",
  jobDeclarationDescription:
    "Renew engineers job declaration online and in a fast way",
  eventsDescription:
    "List of all the events done by the order of engineers and architects in Tripoli",
  galleryDescription:
    "Photo gallery of the events and activities in collaboration with the order of engineers and architects in Tripoli",
  lawsDescription:
    "Laws and regulations of the order of engineers and architects in Tripoli",
  complaintsDescription: "Suggestions posted by the engineers",
  myregistrationsDescription:
    "My registrations to the events and trainings in the order of engineers and architects in Tripoli",
  newsDescription:
    "List of all the news and annoucements in the order of engineers and architects in Tripoli",
  technicalDocumentsDescription:
    "Technical Documents that may be useful for the engineers",
  trainingsDescription:
    "List of all the trainings offered by the order of engineers and architects in Tripoli",
  visitusDescription: "Visit the order of engineers and architects in Tripoli",
  engineerZoneDescription:
    "The engineers zone offer a variety of services and bouquets for all the engineers and architects",
  homepageDescription:
    "Home page of the official website for the order of engineers and architects in Tripoli",
  jobsDescription:
    "Jobs posted on the official website of the order of engineers and architects in Tripoli",
  reportsDescription:
    "Reports and statistics related to the order of engineers and architects in Tripoli",
  calculatorDescription:
    "The calculator containing registration fees and engineer fees help the engineers calculate the fees easily and quickly",
  technicalOfficePubs: "Technical Office Publications",
  technicalOffice: "Technical Office",
  download: "Download",
  arbitration: "Engineering Arbitration",
  arbitrationCenter: "Engineering Arbitration Center",
  deleteAccount: "Delete Your Account",
  deleteAccountMsg: "To delete your account, please contact us",
  statuteOfArbitration: "Statute of Engineering Arbitration Center",
  arbitrationRules: "Arbitration rules on the Engineering Arbitration Center",
  arbitrationCosts: "Arbitration costs",
  arbitrationClause: "Arbitration clause",
  arbitrationRequest: "Request for arbitration",
  respondingToArbitrationRequest: "Response to the request of arbitration",
  arbitrationAcceptance:
    "Arbitrator's statement of acceptance and independance",
  formsAndDocs: "Forms and documents",
  presidentWord: "President word",
  syndicateOverview: "Syndicate overview",
  joinOea: "Join the union, membership conditions",
  financialAndMedicalTransactions: "Financial And Medical Transactions",
  financialAndMedicalTransactionsDesc:
    "The workers' teams provide hospitalization and surgery coverage for engineers affiliated with unions, their families, parents, siblings and their families. The insurance contract for the union year begins on the first of March of each year and ends on the last of February of the following year, and the last deadline for insurance subscription is the end of April.",
  monthlyReports: "Monthly Reports",
  webinarsSchedule:
    "Schedule of monthly conferences and seminars in the union building",
  sideMenuTitle: "Menu",
  changeMode: "Change Mode",
  signIn: "Sign In",
  signUp: "Sign Up",
  moreAnnouncements: "More Announcements",
  moreNews: "More News",
  noInfo: "No Information",
  nakabaBuilding: "Syndicate building",
  internalNumbers: "Internal office code",
  searchPlaceholder: "Search for your country",
  noCountry: "There is no country with this name",
  akkarBuilding: "Akkar Building",
  lab: "Lab",
  currentTrainings: "Current Trainings",
  futureTrainings: "Future Trainings",
  pastTrainings: "Past Trainings",
  membershipTerms: "Membership Terms",
  technicalOfficeDesc: `Welcome to the Transactions Registration Section page. The Technical Office registers construction, surveying and soil transactions for engineers affiliated with the Engineers Syndicate in Tripoli.`,
  previousCouncils: "Previous Councils",
};
