const exploreOEALinks = [
  {
    href: "/aboutus/aboutoea",
    as: "/aboutus/aboutoea",
    title: "aboutOEA",
  },
  {
    href: "/aboutus/boardmembers",
    as: "/aboutus/boardmembers",
    title: "boardOfMembers",
  },
  {
    href: "/aboutus/organizationchart",
    as: "/aboutus/organizationchart",
    title: "organizationChart",
  },
  {
    href: "/aboutus/branches",
    as: "/aboutus/branches",
    title: "branches",
  },
  {
    href: "/aboutus/committees",
    as: "/aboutus/committees",
    title: "associations",
  },
];

const servicesLinks = [
  {
    href: "/trainings/[...params]",
    as: "/trainings/1",
    title: "trainings",
  },
  {
    href: "/events/[...params]",
    as: "/events/1",
    title: "events",
  },
  { href: "/gallery/[page]", as: "/gallery/1", title: "gallery" },
  { href: "/laws", as: "/laws", title: "lawsAndRegulations" },
  {
    href: "/technicalOfficePubs/[category]",
    as: "/technicalOfficePubs/all",
    title: "technicalOfficePubs",
  },
  {
    href: "/arbitration",
    as: "/arbitration",
    title: "arbitrationCenter",
  },
];
const oeaLinks = [
  {
    href: "/presidentWord",
    as: "/presidentWord",
    title: "presidentWord",
  },
  {
    href: "/syndicateOverview",
    as: "/syndicateOverview",
    title: "syndicateOverview",
  },
  { href: "/laws", as: "/laws", title: "lawsAndRegulations" },
  { href: "/joinOEA", as: "/joinOEA", title: "joinOea" },
];
const technicalOfficeLinks = [
  { href: "/technicalOffice", as: "/technicalOffice", title: "monthlyReports" },
  {
    href: "/technicalOfficePubs/[category]",
    as: "/technicalOfficePubs/all",
    title: "technicalOfficePubs",
  },
];

const newsLinks = [
  {
    title: "Announcements",
    href: "/news/[...params]",
    as: "/news/announcements",
  },
  {
    title: "webinarsSchedule",
    href: "/webinarsSchedule",
    as: "/webinarsSchedule",
  },
  {
    title: "Activities",
    href: "/news/[...params]",
    as: "/news/activities",
  },
  // {
  //   title: "Mortality",
  //   href: "/news/[...params]",
  //   as: "/news/mortality",
  // },
  {
    title: "discountsAndBenefits",
    href: "/benefits/[page]",
    as: "/benefits/1",
  },
];
module.exports = {
  exploreOEALinks,
  servicesLinks,
  oeaLinks,
  technicalOfficeLinks,
  newsLinks,
};
